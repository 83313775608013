import { useEffect, useState } from 'react';
import { Route, createGroup, createRouter, defineRoute, param } from 'type-route';

import { accountSection } from './route_types/accountSection';
import { addonFullScreenOverlayParams } from './route_types/addonFullScreenOverlay';
import { billingFullScreenOverlayParams } from './route_types/billingFullScreenOverlay';
import { billingSimplePricingFullScreenOverlayParams } from './route_types/billingSimplePricingFullScreenOverlay';
import { breadcrumb } from './route_types/breadcrumb';
import { groupTab } from './route_types/groupTab';
import { IntegrationsProvider } from './route_types/integrations';
import { internalIndexValueSerializer } from './route_types/internalIndex';

// https://zilch.dev/type-route/docs/introduction/getting-started
export const { RouteProvider, useRoute, routes, session } = createRouter({
  /* Un-authenticated Routes */
  root: defineRoute(
    {
      tg: param.query.optional.string,
      tv: param.query.optional.number,
      no_trial: param.query.optional.boolean,
      feature: param.query.optional.string,
      partner_source: param.query.optional.string,
      industry: param.query.optional.string,
      checkout_token: param.query.optional.string,
      onboarding: param.query.optional.string,
    },
    () => '/backdrop'
  ),
  login: defineRoute(
    {
      account_id: param.query.optional.number,
      email: param.query.optional.string,
      otp_token: param.query.optional.string,
      redirect_path: param.query.optional.string,
      show_notification: param.query.optional.string,
      slug: param.path.string,
    },
    (path) => `/${path.slug}/users/sign_in`
  ),
  universalLogin: defineRoute(
    {
      redirect_path: param.query.optional.string,
    },
    () => '/accounts/universal_login'
  ),
  accountSelection: defineRoute(
    {
      email: param.query.optional.string,
      otp_token: param.query.optional.string,
      redirect_path: param.query.optional.string,
    },
    () => '/accounts/select'
  ),
  forgotPassword: defineRoute(
    {
      redirect_path: param.query.optional.string,
      slug: param.path.string,
    },
    (path) => `/${path.slug}/users/password/new`
  ),
  resetPassword: defineRoute(
    {
      reset_password_token: param.query.optional.string,
      slug: param.path.string,
    },
    ({ slug }) => `/${slug}/users/password/edit`
  ),
  acceptInvitation: defineRoute(
    { slug: param.path.string, invitation_token: param.query.optional.string },
    ({ slug }) => `/${slug}/users/invitation/accept`
  ),
  ssoAuth: defineRoute(
    {
      account_slug: param.query.string,
      partner_slug: param.query.string,
      provider: param.path.string,
      redirect_path: param.query.optional.string,
      remember_me: param.query.optional.boolean,
    },
    (path) => `/users/auth/${path.provider}`
  ),
  internalAuthIndex: defineRoute(
    {
      feature: param.path.ofType(internalIndexValueSerializer),
      signature_export: param.query.optional.boolean,
    },
    (path) => `/internal_index/${path.feature}`
  ),
  signUpLinkExpired: defineRoute({}, () => '/sign_up_link_expired'),

  /* Authenticated Routes */
  billing: defineRoute({ slug: param.path.string }, (path) => `/${path.slug}/settings/billing`),
  comparePlans: defineRoute({ slug: param.path.string }, (path) => `/${path.slug}/compare_plans`),
  consume: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/steps/${path.id}/consume`
  ),
  editor: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/steps/${path.id}/editor`
  ),
  stepVersionHistory: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/steps/${path.id}/editor/version_history`
  ),
  courseConsume: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/courses/${path.id}/consume`
  ),
  courseEditor: defineRoute(
    { slug: param.path.string, id: param.path.number, redirecting: param.state.optional.boolean },
    (path) => `/${path.slug}/courses/${path.id}/editor`
  ),
  flowchartConsume: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/flowcharts/${path.id}/consume`
  ),
  flowchartEditor: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/flowcharts/${path.id}/editor`
  ),
  videoEdit: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/videos/${path.id}/editor`
  ),
  videoConsume: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/videos/${path.id}/consume`
  ),
  content: defineRoute(
    {
      slug: param.path.string,
      sector: param.query.optional.string,
      curriculum_deleted: param.query.optional.boolean,
    },
    (path) => `/${path.slug}/content`
  ),
  contentOrder: defineRoute({ slug: param.path.string }, (path) => `/${path.slug}/content/order`),
  curriculumAdmin: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      onboarding_task: param.query.optional.string,
      scrollToElementId: param.state.optional.number,
      tab: param.query.optional.string,
    },
    (path) => `/${path.slug}/curriculums/${path.id}/admin`
  ),
  curriculumEdit: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      onboarding_task: param.query.optional.string,
      scrollToElementId: param.state.optional.number,
      tab: param.query.optional.string,
    },
    (path) => `/${path.slug}/curriculums/${path.id}/edit`
  ),
  curriculumV2Edit: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      onboarding_task: param.query.optional.string,
      scrollToElementId: param.state.optional.number,
      tab: param.query.optional.string,
    },
    (path) => `/${path.slug}/curriculums/${path.id}/v2edit`
  ),
  curriculumShow: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      flashMessage: param.state.optional.string,
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/curriculums/${path.id}`
  ),
  curriculumPrint: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      course_ids: param.query.optional.array.string,
    },
    (path) => `/${path.slug}/curriculums/${path.id}/print_pdf`
  ),
  curriculumRequestAccess: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/curriculums/${path.id}/request_access`
  ),
  directory: defineRoute(
    {
      slug: param.path.string,
      slideout: param.query.optional.string,
      'slideout-user-profile-userId': param.query.optional.number,
    },
    (path) => `/${path.slug}/people`
  ),
  manageUsers: defineRoute(
    {
      slug: param.path.string,
      open_react_finch_upload_modal: param.query.optional.boolean,
      open_finch_deactivate_users_modal: param.query.optional.boolean,
      open_bulk_user_upload_modal: param.query.optional.boolean,
      update_upload_modal: param.query.optional.boolean,
    },
    (path) => `/${path.slug}/users`
  ),
  surveyEditor: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/surveys/${path.id}/editor`
  ),
  surveyQuestionEditor: defineRoute(
    { slug: param.path.string, id: param.path.number, surveyId: param.path.number },
    (path) => `/${path.slug}/surveys/${path.surveyId}/questions/${path.id}/editor`
  ),
  surveyConsume: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/surveys/${path.id}/consume`
  ),
  surveyResults: defineRoute(
    { slug: param.path.string, surveyId: param.path.number, id: param.path.number },
    (path) => `/${path.slug}/surveys/${path.surveyId}/attempts/${path.id}/results`
  ),
  userProfile: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
      show_edit_user_modal: param.query.optional.boolean,
    },
    (path) => `/${path.slug}/users/${path.id}`
  ),
  userTrainingPath: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/users/${path.id}/training_path`
  ),
  userTrainingPathConfig: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/users/${path.id}/training_path/edit`
  ),
  userAssignedCurriculums: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
    },
    (path) => `/${path.slug}/users/${path.id}/assigned_curriculums`
  ),
  integrations: defineRoute(
    {
      slug: param.path.string,
      search: param.query.optional.string,
      sort: param.query.optional.string,
    },
    (path) => `/${path.slug}/settings/integrations`
  ),
  integration: defineRoute(
    { slug: param.path.string, provider: param.path.ofType(IntegrationsProvider) },
    (path) => `/${path.slug}/settings/integrations/${path.provider}`
  ),
  mySettings: defineRoute(
    { slug: param.path.string, open_update_password_modal: param.query.optional.boolean },
    (path) => `/${path.slug}/my_settings`
  ),
  upgradeSimplePricingPlan: defineRoute(
    billingSimplePricingFullScreenOverlayParams,
    (path) => `/${path.slug}/upgrade_plan`
  ),
  pickASimplePricingPlan: defineRoute(
    billingSimplePricingFullScreenOverlayParams,
    (path) => `/${path.slug}/pick_a_plan`
  ),
  manageSeats: defineRoute(billingFullScreenOverlayParams, (path) => `/${path.slug}/manage_seats`),
  downgradePlan: defineRoute(
    billingFullScreenOverlayParams,
    (path) => `/${path.slug}/downgrade_plan`
  ),
  upgradePlan: defineRoute(billingFullScreenOverlayParams, (path) => `/${path.slug}/upgrade_plan`),
  configurePlan: defineRoute(
    billingFullScreenOverlayParams,
    (path) => `/${path.slug}/configure_plan`
  ),
  home: defineRoute(
    {
      slug: param.path.string,
      kind: param.query.optional.string,
      upload_curriculum_with_ai: param.query.optional.boolean,
      ai_completion_id: param.query.optional.string,
    },
    (path) => `/${path.slug}`
  ),
  orgChart: defineRoute({ slug: param.path.string }, (path) => `/${path.slug}/org_chart`),
  roleChart: defineRoute(
    { slug: param.path.string, slideout: param.query.optional.string },
    (path) => `/${path.slug}/role_chart`
  ),
  groups: defineRoute(
    {
      slug: param.path.string,
      show_create_group_kind_modal: param.query.optional.boolean,
      group_kind: param.query.optional.string,
    },
    (path) => `/${path.slug}/groups`
  ),
  group: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      newGroup: param.query.optional.boolean,
      tab: param.path.ofType(groupTab),
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/groups/${path.id}/edit/${path.tab}`
  ),
  allUsersReport: defineRoute(
    { slug: param.path.string },
    (path) => `/${path.slug}/all_users_report`
  ),
  allCurriculumsReport: defineRoute(
    { slug: param.path.string },
    (path) => `/${path.slug}/curriculum_report`
  ),
  latestActivityReport: defineRoute(
    { slug: param.path.string, filter_user_id: param.query.optional.number },
    (path) => `/${path.slug}/latest_activity_report`
  ),
  individualUserReport: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
      breadcrumbText: param.state.optional.string,
    },
    (path) => `/${path.slug}/individual_user_report/${path.id}`
  ),
  curriculumByUserReport: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/curriculum_report/${path.id}`
  ),
  curriculumByElementReport: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      breadcrumb: param.state.optional.ofType(breadcrumb),
    },
    (path) => `/${path.slug}/curriculum_report/${path.id}/by_element`
  ),
  courseReport: defineRoute(
    {
      slug: param.path.string,
      curriculumId: param.path.number,
      courseId: param.path.number,
    },
    (path) => `/${path.slug}/curriculum_report/${path.curriculumId}/course/${path.courseId}`
  ),
  surveyReport: defineRoute(
    {
      slug: param.path.string,
      curriculumId: param.path.number,
      surveyId: param.path.number,
    },
    (path) => `/${path.slug}/curriculum_report/${path.curriculumId}/survey/${path.surveyId}`
  ),
  surveyByUserReport: defineRoute(
    {
      slug: param.path.string,
      curriculumId: param.path.number,
      surveyId: param.path.number,
      attemptId: param.path.number,
      userId: param.state.optional.number,
    },
    (path) =>
      `/${path.slug}/curriculum_report/${path.curriculumId}/survey/${path.surveyId}/attempts/${path.attemptId}`
  ),
  reportsUpgrade: defineRoute(
    { slug: param.path.string },
    (path) => `/${path.slug}/reports_upgrade`
  ),
  templates: defineRoute(
    {
      slug: param.path.string,
      search: param.query.optional.string,
      sort: param.query.optional.string,
      category_ids: param.query.optional.array.string,
      author: param.query.optional.array.string,
      suggested_groups: param.query.optional.array.string,
      tags: param.query.optional.array.string,
      sectors: param.query.optional.array.string,
      sector: param.query.optional.string,
      slideout: param.query.optional.string,
      page: param.query.optional.number,
      view_by: param.query.optional.string,
    },
    (path) => `/${path.slug}/templates`
  ),
  template: defineRoute(
    {
      slug: param.path.string,
      id: param.path.number,
      sector: param.query.optional.string,
      breadcrumb: param.state.optional.ofType(breadcrumb),
      curriculum_element_id: param.query.optional.number,
    },
    (path) => `/${path.slug}/templates/${path.id}`
  ),
  templatesSearch: defineRoute(
    {
      slug: param.path.string,
      author: param.query.optional.array.string,
      search: param.query.optional.string,
      category_ids: param.query.optional.array.string,
      goals: param.query.optional.array.string,
      tags: param.query.optional.array.string,
      suggested_role_names: param.query.optional.array.string,
      sectors: param.query.optional.array.string,
    },
    (path) => `/${path.slug}/templates/search`
  ),
  search: defineRoute(
    {
      slug: param.path.string,
      search: param.query.string,
      filter: param.query.optional.string,
      filters: param.query.optional.array.string,
      kind: param.query.optional.string,
      page: param.query.optional.number,
    },
    (path) => `/${path.slug}/search`
  ),
  completionCertificate: defineRoute(
    {
      slug: param.path.string,
      completionId: param.path.number,
    },
    (path) => `/${path.slug}/completions/${path.completionId}.pdf`
  ),
  createCurriculumFullscreenOverlay: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/create_curriculum`
  ),
  accountSettings: defineRoute(
    {
      slug: param.path.string,
      section: param.query.optional.ofType(accountSection),
      show_update_logo_modal: param.query.optional.boolean,
      tab: param.query.optional.string,
    },
    (path) => `/${path.slug}/settings/account`
  ),
  notifications: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/notifications`
  ),
  requests: defineRoute(
    {
      slug: param.path.string,
      sector: param.query.optional.string,
    },
    (path) => `/${path.slug}/requests`
  ),
  archivedRequests: defineRoute(
    {
      slug: param.path.string,
      sector: param.query.optional.string,
    },
    (path) => `/${path.slug}/requests/archived`
  ),
  manageCompletions: defineRoute(
    {
      curriculumId: param.path.number,
      slug: param.path.string,
      sector: param.query.optional.string,
    },
    (path) => `/${path.slug}/curriculums/${path.curriculumId}/manage_completions`
  ),
  bulkDocumentImport: defineRoute(
    {
      slug: param.path.string,
      sector: param.state.optional.string,
    },
    (path) => `/${path.slug}/bulk_document_import`
  ),
  checkout: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/checkout`
  ),
  signUpLinkUsed: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/sign_up_link_used`
  ),
  delegationPlanner: defineRoute({ slug: param.path.string }, (path) => `/${path.slug}/delegation`),
  delegationPlannerBoard: defineRoute(
    { slug: param.path.string, id: param.path.number },
    (path) => `/${path.slug}/delegation/boards/${path.id}`
  ),
  addonOverlay: defineRoute(
    addonFullScreenOverlayParams,
    (path) => `/${path.slug}/addons/${path.name}`
  ),
  aiBotPoc: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/ai_bot_poc`
  ),
  coursesAndTemplates: defineRoute(
    {
      slug: param.path.string,
    },
    (path) => `/${path.slug}/courses_templates`
  ),
});

export const loginGroup = createGroup([
  routes.universalLogin,
  routes.login,
  routes.accountSelection,
  routes.forgotPassword,
  routes.resetPassword,
  routes.acceptInvitation,
]);

export const peopleGroup = createGroup([routes.directory, routes.orgChart, routes.roleChart]);

export const reportsGroup = createGroup([
  routes.allUsersReport,
  routes.allCurriculumsReport,
  routes.latestActivityReport,
  routes.individualUserReport,
  routes.curriculumByUserReport,
  routes.curriculumByElementReport,
  routes.courseReport,
  routes.surveyReport,
  routes.surveyByUserReport,
  routes.reportsUpgrade,
]);

const manageUsersGroup = createGroup([routes.manageUsers, routes.userProfile]);

export const accountSettingsGroup = createGroup([
  manageUsersGroup,
  routes.billing,
  routes.integrations,
  routes.integration,
]);

export const billingGroup = createGroup([
  routes.billing,
  routes.reportsUpgrade,
  routes.configurePlan,
  routes.upgradePlan,
  routes.downgradePlan,
  routes.manageSeats,
  routes.comparePlans,
  routes.checkout,
]);

export const nativeAppNudgeGroup = createGroup([
  routes.curriculumShow,
  routes.directory,
  routes.manageUsers,
  routes.home,
  routes.search,
  routes.userAssignedCurriculums,
]);

export const allowedAccessOnHold = createGroup([
  routes.upgradeSimplePricingPlan,
  routes.pickASimplePricingPlan,
  routes.configurePlan,
  routes.comparePlans,
  routes.upgradePlan,
  routes.billing,
  routes.manageUsers,
  routes.userProfile,
]);

export const allowedAccessNoTrial = createGroup([
  routes.configurePlan,
  routes.pickASimplePricingPlan,
  routes.comparePlans,
]);

export const allowedAccessCheckoutTokenBasedAccount = createGroup([routes.checkout]);

export const marketplacesGroup = createGroup([routes.coursesAndTemplates]);

export const useRouterHelper = () => {
  const [previousRoute, setPreviousRoute] = useState<Route<typeof routes> | null>(null);
  let route: Route<typeof routes> | undefined;

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    route = useRoute();
  } catch {
    /* type-route: Temporarily needed while app is being migrated to fully use type-route library */
  }

  useEffect(() => {
    if (route) setPreviousRoute(route);
  }, [route]);

  if (!route) return null;

  return {
    route,
    previousRoute,
  };
};
