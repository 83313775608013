import { Addon } from '../types/Addon';

const gcsReactImagesBaseUrl =
  'https://storage.googleapis.com/trainual-prod-web-assets-public/react/images';

export const NoResultsGraphicDark = `${gcsReactImagesBaseUrl}/telescope_dark.svg`;
export const NoResultsGraphic = `${gcsReactImagesBaseUrl}/telescope.svg`;
export const GroupsPageImage = `${gcsReactImagesBaseUrl}/groups_page_banner_illustration.svg`;
export const AddUserIllustration = `${gcsReactImagesBaseUrl}/add_user_illustration.svg`;
export const CurriculumAssignmentsEmptyState = `${gcsReactImagesBaseUrl}/empty_state/curriculums-assignments-empty-state.svg`;
export const TrainualULogo = `${gcsReactImagesBaseUrl}/home/trainual_u_logo.png`;
export const TrainingPathCaughtUpForNow = `${gcsReactImagesBaseUrl}/training_path_caught_up_for_now.svg`;
export const TrainualPlusWidgetBackground = `${gcsReactImagesBaseUrl}/home/trainual_plus_widget_background.svg`;
export const TrainualLogo = `${gcsReactImagesBaseUrl}/trainual-logo.svg`;
export const TrainualLogoAvatar = `${gcsReactImagesBaseUrl}/trainual_logo_avatar.svg`;
export const TrainualLogoIcon = `${gcsReactImagesBaseUrl}/trainual-logo-icon.svg`;
export const DropZoneImage = `${gcsReactImagesBaseUrl}/drop_zone.svg`;
export const DefaultUploadAvatarIcon = `${gcsReactImagesBaseUrl}/default_upload_avatar_icon.svg`;
export const FavoriteEmptyStateImage = `${gcsReactImagesBaseUrl}/empty_state/favorite-empty-state.svg`;
export const PoweredByTrainualDark = `${gcsReactImagesBaseUrl}/powered-by-trainual-dark.svg`;
export const PoweredByTrainual = `${gcsReactImagesBaseUrl}/powered-by-trainual.png`;
export const SearchesEmptyState = `${gcsReactImagesBaseUrl}/searches_empty_state.svg`;
export const SearchesEmptyStateDark = `${gcsReactImagesBaseUrl}/searches_empty_state_dark.svg`;
export const ReportsEmptyState = `${gcsReactImagesBaseUrl}/reports-empty-state.svg`;
export const ReportsEmptyStateDark = `${gcsReactImagesBaseUrl}/reports-empty-state-dark.svg`;
export const ReportsPaywallEmptyState = `${gcsReactImagesBaseUrl}/empty_state/reports-paywall-empty-state-light.svg`;
export const ReportsPaywallEmptyStateDark = `${gcsReactImagesBaseUrl}/empty_state/reports-paywall-empty-state-dark.svg`;
export const UsersEmptyState = `${gcsReactImagesBaseUrl}/empty_state/users-empty-state.svg`;
export const ThreeDotLoaderGif = `${gcsReactImagesBaseUrl}/loaders/three-dot-loader.gif`;
export const ResponsibilityEmptyStateImage = `${gcsReactImagesBaseUrl}/empty_state/responsibility-linked-content.svg`;
export const GroupManagersEmptyState = `${gcsReactImagesBaseUrl}/empty_state/group-managers-empty-state-colored.svg`;
export const DelegationPlannerUncheckedImage = `${gcsReactImagesBaseUrl}/addons/trainual_plus/delegation_planner_opt_in.svg`;
export const PlusOptedInImage = `${gcsReactImagesBaseUrl}/addons/trainual_plus/opted_in.svg`;
export const DelegationBoardEmptyStateImage = `${gcsReactImagesBaseUrl}/empty_state/delegation-planner-board-empty-state.svg`;
export const CornerBg = `${gcsReactImagesBaseUrl}/sing_up_link_expired/sing_up_link_expired_conner.svg`;
export const SignUpLinkExpiredImage = `${gcsReactImagesBaseUrl}/sing_up_link_expired/sing_up_link_expired_clock.svg`;
export const ViewByEmptyStateSvg = `${gcsReactImagesBaseUrl}/empty_state/view-by-empty-state.svg`;
export const AllContentImage = `${gcsReactImagesBaseUrl}/all_content_illustration.svg`;
export const CompanyImage = `${gcsReactImagesBaseUrl}/company_illustration.svg`;
export const PolicyImage = `${gcsReactImagesBaseUrl}/policy_illustration.svg`;
export const ProcessImage = `${gcsReactImagesBaseUrl}/process_illustration.svg`;
export const LibraryEmptyStateSvg = `${gcsReactImagesBaseUrl}/empty_state/content-library-empty-state-colored.svg`;
export const ContentSvg = `${gcsReactImagesBaseUrl}/flowchart/content.svg`;
export const GroupsSvg = `${gcsReactImagesBaseUrl}/flowchart/group.svg`;
export const PersonSvg = `${gcsReactImagesBaseUrl}/flowchart/person.svg`;
export const CelebrationSvg = `${gcsReactImagesBaseUrl}/celebration_circle.svg`;
export const ShapesSvg = `${gcsReactImagesBaseUrl}/flowchart/shapes.svg`;
export const DefaultCollapsedLogo = `${gcsReactImagesBaseUrl}/default_collapsed_logo.svg`;
export const GoogleAutocompletePinIcon = `${gcsReactImagesBaseUrl}/google_autocomplete_pin.svg`;
export const ContentFlyoutNoResultsImage = `${gcsReactImagesBaseUrl}/empty_state/content-flyout-no-results-yet.svg`;
export const ContentFlyoutNoSearchResultsImage = `${gcsReactImagesBaseUrl}/empty_state/content-flyout-no-search-results.svg`;
export const DesktopBackground = `${gcsReactImagesBaseUrl}/mobile_nudge_overlay/desktop_background.svg`;
export const NativeAppBackground = `${gcsReactImagesBaseUrl}/mobile_nudge_overlay/native_app_background.svg`;
export const DesktopImage = `${gcsReactImagesBaseUrl}/mobile_nudge_overlay/desktop.svg`;
export const NativeAppImage = `${gcsReactImagesBaseUrl}/mobile_nudge_overlay/native_app.svg`;
export const EmptyMediaPreview = `${gcsReactImagesBaseUrl}/media_preview.svg`;
export const DiscountPigSvg = `${gcsReactImagesBaseUrl}/billing/discount_pig.svg`;
export const ManagePlanDiscountPigSvg = `${gcsReactImagesBaseUrl}/billing/manage_plan_discount_pig.svg`;
export const WatchVideoSvg = `${gcsReactImagesBaseUrl}/empty_state/empty-state-watch-video.svg`;
export const EveryoneGroupImage = `${gcsReactImagesBaseUrl}/everyone_group.svg`;
export const GroupsIndexEmptyStateDark = `${gcsReactImagesBaseUrl}/empty_state/groups-index-empty-state-dark.svg`;
export const GroupsIndexEmptyStateLight = `${gcsReactImagesBaseUrl}/empty_state/groups-index-empty-state-light.svg`;
export const TrianglePurple = `${gcsReactImagesBaseUrl}/purple-triangle-confetti.svg`;
export const TriangleYellow = `${gcsReactImagesBaseUrl}/texture-triangle-yellow.svg`;
export const AIOutlinerSlideoutBanner = `${gcsReactImagesBaseUrl}/ai-outliner-slideout-banner.svg`;
export const CompletionsEmptyState = `${gcsReactImagesBaseUrl}/empty_state/completions-tab-empty-state.svg`;
export const FiltersEmptyStateDark = `${gcsReactImagesBaseUrl}/empty_state/filters-empty-dark.svg`;
export const FiltersEmptyStateLight = `${gcsReactImagesBaseUrl}/empty_state/filters-empty-light.svg`;
export const CurriculumsEmptyStateLight = `${gcsReactImagesBaseUrl}/empty_state/curriculums-empty-state.svg`;
export const CurriculumsEmptyStateDark = `${gcsReactImagesBaseUrl}/empty_state/curriculums-empty-state-dark.svg`;
export const UnverifiedBadge = `${gcsReactImagesBaseUrl}/unverified.svg`;
export const VerifiedBadge = `${gcsReactImagesBaseUrl}/verified.svg`;
export const ImportDocumentExpanded = `${gcsReactImagesBaseUrl}/create_curriculum_overlay/import_document_expanded.svg`;
export const CreateFromTemplateExpanded = `${gcsReactImagesBaseUrl}/create_curriculum_overlay/templates_expanded.svg`;
export const ImportDocument = `${gcsReactImagesBaseUrl}/create_curriculum_overlay/import_document.svg`;
export const CreateFromScratchImage = `${gcsReactImagesBaseUrl}/create_curriculum_overlay/pen.svg`;
export const CreateFromTemplate = `${gcsReactImagesBaseUrl}/create_curriculum_overlay/templates.svg`;
export const NoUsersSVG = `${gcsReactImagesBaseUrl}/no_users_exist.svg`;
export const ESignaturePaywallBannerLogo = `${gcsReactImagesBaseUrl}/addons/e_signature/addon_logo_e_signature.svg`;
export const DocumentImportImage = `${gcsReactImagesBaseUrl}/document_import.svg`;
export const CurriculumImage = `${gcsReactImagesBaseUrl}/curriculum/image.svg`;
export const GoogleDriveLogo = `${gcsReactImagesBaseUrl}/google_drive_logo.svg`;
export const Separator = `${gcsReactImagesBaseUrl}/checkout_overlay/separator.svg`;
export const VideoMarketingSidebarHeader = `${gcsReactImagesBaseUrl}/video-marketing-sidebar-header.svg`;
export const VideoModalUploadDragAndDrop = `${gcsReactImagesBaseUrl}/video-modal-upload-drag-and-drop.svg`;
export const VideoModalUploadGraphic = `${gcsReactImagesBaseUrl}/video-modal-upload-graphic.svg`;
export const AdpRunLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/adp-run.svg`;
export const AdpWorkforceNowLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/adp-workforce-now.svg`;
export const BambooHrLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/bamboo-hr.svg`;
export const GustoLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/gusto.svg`;
export const InsperityLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/insperity.svg`;
export const JustworksLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/justworks.svg`;
export const NamelyLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/namely.svg`;
export const PaycomLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/paycom.svg`;
export const PaylocityLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/paylocity.svg`;
export const QuickbooksLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/quickbooks.svg`;
export const SquarePayrollLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/square-payroll.svg`;
export const TrinetLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/trinet.svg`;
export const WorkdayLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/workday.svg`;
export const ZenefitsLogo = `${gcsReactImagesBaseUrl}/AIBot/integrations/logos/zenefits.svg`;
export const ContentStructure = `${gcsReactImagesBaseUrl}/AIBot/content-structure.svg`;
export const AIBotIcon = `${gcsReactImagesBaseUrl}/AIBot/ai-bot-icon.svg`;
export const ComposeSvg = `${gcsReactImagesBaseUrl}/ai/compose-step.svg`;
export const ComposeRoleChartSvg = `${gcsReactImagesBaseUrl}/ai/compose-terms-role-chart.svg`;
export const CloseButtonIcon = `${gcsReactImagesBaseUrl}/close_button_icon.svg`;
export const NoGroupResultsGraphicDark = `${gcsReactImagesBaseUrl}/no_groups_state_dark.svg`;
export const NoGroupResultsGraphic = `${gcsReactImagesBaseUrl}/no_groups_state.svg`;
export const NoContentGraphicDark = `${gcsReactImagesBaseUrl}/add_content_state_dark.svg`;
export const NoContentGraphic = `${gcsReactImagesBaseUrl}/no_groups_state.svg`;
export const NoUsersGraphicDark = `${gcsReactImagesBaseUrl}/no_users_empty_state_dark.svg`;
export const NoUsersGraphic = `${gcsReactImagesBaseUrl}/no_users_empty_state.svg`;
export const OrgChartGraphicDark = `${gcsReactImagesBaseUrl}/org_chart_dark.svg`;
export const OrgChartGraphic = `${gcsReactImagesBaseUrl}/org_chart.svg`;
export const TrainingPathIllustration = `${gcsReactImagesBaseUrl}/training_paths/illustration.svg`;
export const HeaderImage = `${gcsReactImagesBaseUrl}/add_teammate_modal.svg`;
export const VideoResponseEmptyStateImage = `${gcsReactImagesBaseUrl}/empty_state/video-response-empty-state.svg`;
export const LoomLogo = `${gcsReactImagesBaseUrl}/loom_logo.svg`;
export const CalendarIllustrationIcon = `${gcsReactImagesBaseUrl}/calendar_illustration_icon.svg`;
export const TodoListIcon = `${gcsReactImagesBaseUrl}/todo_list_icon.svg`;
export const BackgroundImageLg = `${gcsReactImagesBaseUrl}/public_application/sign-up-bg-lg.jpeg`;
export const BackgroundImageMd = `${gcsReactImagesBaseUrl}/public_application/sign-up-bg-md.jpeg`;
export const BackgroundImageSm = `${gcsReactImagesBaseUrl}/public_application/sign-up-bg-sm.jpeg`;
export const LoaderSrc = `${gcsReactImagesBaseUrl}/loaders/create-account-loader.gif`;
export const DashedLineImage = `${gcsReactImagesBaseUrl}/public_application/sign_up_info/dashed_line_with_star.svg`;
export const HomePageTabletImage = `${gcsReactImagesBaseUrl}/public_application/sign_up_info/home_page_tablet.svg`;
export const HomePageImage = `${gcsReactImagesBaseUrl}/public_application/sign_up_info/home_page.svg`;
export const LogoWithTitleTabletImage = `${gcsReactImagesBaseUrl}/public_application/sign_up_info/logo_with_title_tablet.svg`;
export const LogoWithTitleImage = `${gcsReactImagesBaseUrl}/public_application/sign_up_info/logo_with_title.svg`;
export const GeneratedTestNotification = `${gcsReactImagesBaseUrl}/generated_test_notification.svg`;
export const UploadNotification = `${gcsReactImagesBaseUrl}/upload_notification.svg`;
export const AddonInfoCardShine = `${gcsReactImagesBaseUrl}/billing/addon_info_card_shine.svg`;
export const AddonInfoCardArrow = `${gcsReactImagesBaseUrl}/billing/addon_info_card_arrow.svg`;
export const GoYearlyAndSaveImage = `${gcsReactImagesBaseUrl}/billing/simple_pricing_go_yearly_and_save.svg`;
export const MoveToNewPlanArrowImage = `${gcsReactImagesBaseUrl}/billing/move_to_new_plan_arrow.svg`;
export const AnnualSavingsSvg = `${gcsReactImagesBaseUrl}/billing/annual_savings.svg`;
export const RaysSvg = `${gcsReactImagesBaseUrl}/billing/rays.svg`;
export const TrainualPlusPowerup = `${gcsReactImagesBaseUrl}/addons/trainual_plus/powerup.svg`;
export const PowerUpNoSplash = `${gcsReactImagesBaseUrl}/addons/trainual_plus/powerup-no-splash.svg`;
export const TrainualPlusGroup = `${gcsReactImagesBaseUrl}/addons/trainual_plus/group.svg`;
export const EmptyStateCardSvg = `${gcsReactImagesBaseUrl}/billing/empty_state_card.svg`;
export const CoverImage = `${gcsReactImagesBaseUrl}/simple-banner-image.webp`;
export const ReferFriendBannerImage = `${gcsReactImagesBaseUrl}/billing/refer_a_friend_banner_image.svg`;
export const EmptyStateBillingInfoSvg = `${gcsReactImagesBaseUrl}/billing/empty_state_billing_info.svg`;
export const RecommendedImage = `${gcsReactImagesBaseUrl}/billing/recommended.svg`;
export const ReferFriendModalHeader = `${gcsReactImagesBaseUrl}/billing/refer_a_friend_modal_header.svg`;
export const NoInvoicesEmptyState = `${gcsReactImagesBaseUrl}/empty_state/no-invoices-empty-state.svg`;
export const DoubleSpinArrowImage = `${gcsReactImagesBaseUrl}/billing/double_spin_arrow.svg`;
export const PastDueModalHeader = `${gcsReactImagesBaseUrl}/billing/past_due_modal_header.svg`;
export const TrialEndedModalBackground = `${gcsReactImagesBaseUrl}/billing/trial_ended_modal_background.svg`;
export const ReactivateAccountModalHeader = `${gcsReactImagesBaseUrl}/billing/reactivate_account_celebration_modal_header.svg`;
export const Logo = `${gcsReactImagesBaseUrl}/logo-sm.svg`;
export const SignatureLoader = `${gcsReactImagesBaseUrl}/signature-loader.svg`;
export const Abstract = `${gcsReactImagesBaseUrl}/abstract.svg`;
export const Airtable = `${gcsReactImagesBaseUrl}/airtable.svg`;
export const Avocode = `${gcsReactImagesBaseUrl}/avocode.svg`;
export const Codepen = `${gcsReactImagesBaseUrl}/codepen.svg`;
export const Codesandbox = `${gcsReactImagesBaseUrl}/codesandbox.svg`;
export const Dribble = `${gcsReactImagesBaseUrl}/dribble.svg`;
export const Droplr = `${gcsReactImagesBaseUrl}/droplr.svg`;
export const EmbedLink = `${gcsReactImagesBaseUrl}/embed_link.svg`;
export const Facebook = `${gcsReactImagesBaseUrl}/facebook.svg`;
export const Figma = `${gcsReactImagesBaseUrl}/figma.svg`;
export const CodeFile = `${gcsReactImagesBaseUrl}/file-code-regular.svg`;
export const Github = `${gcsReactImagesBaseUrl}/github.svg`;
export const GoogleDataStudio = `${gcsReactImagesBaseUrl}/google_data_studio.svg`;
export const GoogleDocs = `${gcsReactImagesBaseUrl}/google_docs.svg`;
export const GoogleMaps = `${gcsReactImagesBaseUrl}/google_maps.svg`;
export const GoogleSlides = `${gcsReactImagesBaseUrl}/google_slides.svg`;
export const Instagram = `${gcsReactImagesBaseUrl}/instagram.svg`;
export const Keynote = `${gcsReactImagesBaseUrl}/keynote.svg`;
export const Libsyn = `${gcsReactImagesBaseUrl}/libsyn.svg`;
export const Loom = `${gcsReactImagesBaseUrl}/loom_logo.svg`;
export const Marvelapp = `${gcsReactImagesBaseUrl}/marvel_app.svg`;
export const Meetup = `${gcsReactImagesBaseUrl}/meetup.svg`;
export const Overflow = `${gcsReactImagesBaseUrl}/overflow.svg`;
export const Pdf = `${gcsReactImagesBaseUrl}/pdf.svg`;
export const PollEverywhere = `${gcsReactImagesBaseUrl}/poll_everywhere.svg`;
export const RadioPublic = `${gcsReactImagesBaseUrl}/radio_public.svg`;
export const Soundcloud = `${gcsReactImagesBaseUrl}/soundcloud.svg`;
export const Spotify = `${gcsReactImagesBaseUrl}/spotify.svg`;
export const TikTok = `${gcsReactImagesBaseUrl}/tik_tok.svg`;
export const TrainualCapture = `${gcsReactImagesBaseUrl}/trainual_capture_logo.svg`;
export const Trainual = `${gcsReactImagesBaseUrl}/trainual_logo_avatar.svg`;
export const Vectary = `${gcsReactImagesBaseUrl}/vectary.svg`;
export const Vimeo = `${gcsReactImagesBaseUrl}/vimeo.svg`;
export const Yelp = `${gcsReactImagesBaseUrl}/yelp.svg`;
export const Youtube = `${gcsReactImagesBaseUrl}/youtube.svg`;
export const GiphyLogo = `${gcsReactImagesBaseUrl}/PoweredBy_200px-White_HorizText.png`;
export const LibraryEmptyState = `${gcsReactImagesBaseUrl}/library-empty-state.svg`;
export const BulkIcon = `${gcsReactImagesBaseUrl}/home/task_driven/bulk_icon.svg`;
export const DocumentIcon = `${gcsReactImagesBaseUrl}/home/task_driven/document_icon.svg`;
export const HrIcon = `${gcsReactImagesBaseUrl}/home/task_driven/hr_tool_icon.svg`;
export const RoleChartIcon = `${gcsReactImagesBaseUrl}/home/task_driven/role_chart_icon.svg`;
export const RolesIcon = `${gcsReactImagesBaseUrl}/home/task_driven/roles_icon.svg`;
export const ScratchIcon = `${gcsReactImagesBaseUrl}/home/task_driven/scratch_icon.svg`;
export const TemplateIcon = `${gcsReactImagesBaseUrl}/home/task_driven/template_icon.svg`;
export const UserIcon = `${gcsReactImagesBaseUrl}/home/task_driven/user_icon.svg`;
export const GrowthPlanPriceArrow = `${gcsReactImagesBaseUrl}/checkout_overlay/growth_plan_price_arrow.svg`;
export const MediumPlanPriceArrow = `${gcsReactImagesBaseUrl}/checkout_overlay/medium_plan_price_arrow.svg`;
export const SmallPlanPriceArrow = `${gcsReactImagesBaseUrl}/checkout_overlay/small_plan_price_arrow.svg`;
export const TrainualCustomLogo = `${gcsReactImagesBaseUrl}/checkout_overlay/trainual_custom.svg`;
export const TrainualGrowthLogo = `${gcsReactImagesBaseUrl}/checkout_overlay/trainual_growth.svg`;
export const TrainualMediumLogo = `${gcsReactImagesBaseUrl}/checkout_overlay/trainual_medium.svg`;
export const TrainualSmallLogo = `${gcsReactImagesBaseUrl}/checkout_overlay/trainual_small.svg`;
export const TrainualUnlimitedLogo = `${gcsReactImagesBaseUrl}/checkout_overlay/trainual_unlimited.svg`;
export const UnlimitedPlanPriceArrow = `${gcsReactImagesBaseUrl}/checkout_overlay/unlimited_plan_price_arrow.svg`;
export const Amex = `${gcsReactImagesBaseUrl}/billing/cards_icons/amex.svg`;
export const DinersClub = `${gcsReactImagesBaseUrl}/billing/cards_icons/diners_club.svg`;
export const Discover = `${gcsReactImagesBaseUrl}/billing/cards_icons/discover.svg`;
export const JCB = `${gcsReactImagesBaseUrl}/billing/cards_icons/jcb.svg`;
export const MasterCard = `${gcsReactImagesBaseUrl}/billing/cards_icons/mastercard.svg`;
export const UnionPay = `${gcsReactImagesBaseUrl}/billing/cards_icons/union_pay.svg`;
export const Visa = `${gcsReactImagesBaseUrl}/billing/cards_icons/visa.svg`;
export const DocumentPdfImage = `${gcsReactImagesBaseUrl}/document_pdf.svg`;
export const GoogleDocsLogoImage = `${gcsReactImagesBaseUrl}/google_docs.svg`;
export const GoogleLogoImage = `${gcsReactImagesBaseUrl}/google_logo.svg`;
export const DeskDark = `${gcsReactImagesBaseUrl}/desk_dark.svg`;
export const DeskLight = `${gcsReactImagesBaseUrl}/desk.svg`;
export const LibraryEmptyDark = `${gcsReactImagesBaseUrl}/library-empty-state-dark.svg`;
export const LibraryEmptyLight = `${gcsReactImagesBaseUrl}/library-empty-state.svg`;
export const StarsLeftSide = `${gcsReactImagesBaseUrl}/public_application/starts-left-side.svg`;
export const StarsRightSide = `${gcsReactImagesBaseUrl}/public_application/starts-right-side.svg`;
export const CourseSvg = `${gcsReactImagesBaseUrl}/element_type_icons/course.svg`;
export const FlowchartSvg = `${gcsReactImagesBaseUrl}/element_type_icons/flowchart.svg`;
export const SurveySvg = `${gcsReactImagesBaseUrl}/element_type_icons/survey.svg`;
export const VideoSvg = `${gcsReactImagesBaseUrl}/element_type_icons/video.svg`;
export const OutlineCourseLabelIcon = `${gcsReactImagesBaseUrl}/marketplaces/courses_and_templates/courses/course_outline_label_icon.svg`;
export const trainualPlusFeatureSvg = ({ name = Addon.TrainingPath }: { name: Addon }) =>
  `${gcsReactImagesBaseUrl}/addons/trainual_plus/${name}_new.svg`;
